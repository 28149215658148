@media screen and (max-width: 500px) {
    .table__empty {
        height: 80vh;
        width: 100%;
        animation: EmptyAnimation 1s ease-in-out;
        .table__Image1 {
            border-top: 4.5rem solid transparent;
            width: 55%;
            margin: auto;
            display: flex;
            justify-content: center;
        }
        .table__Image2 {
            border-top: 0.5rem solid transparent;
            width: 25%;
            margin: auto;
            display: flex;
            justify-content: center;
        }
    }
}
@keyframes EmptyAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@media screen and (min-width: 501px) and (max-width: 800px) {

    .table__empty {
        height: 60vh;
        width: 100%;
        animation: EmptyAnimation 1s ease-in-out;
        .table__Image1 {
            border-top: 2.5rem solid transparent;
            width: 40%;
            margin: auto;
            display: flex;
            justify-content: center;
        }
        .table__Image2 {
            border-top: 0.5rem solid transparent;
            width: 25%;
            margin: auto;
            display: flex;
            justify-content: center;
        }
    }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {

    .table__empty {
        height: 80vh;
        width: 100%;
        animation: EmptyAnimation 1s ease-in-out;
        .table__Image1 {
            border-top: 4.5rem solid transparent;
            width: 55%;
            margin: auto;
            display: flex;
            justify-content: center;
        }
        .table__Image2 {
            border-top: 0.5rem solid transparent;
            width: 25%;
            margin: auto;
            display: flex;
            justify-content: center;
        }
    }
}
@media screen and (min-device-width:1280px) {
    .table__empty {
        height: 80vh;
        width: 100%;
        animation: EmptyAnimation 1s ease-in-out;
        .table__Image1 {
            border-top: 4.5rem solid transparent;
            width: 25%;
            margin: auto;
            display: flex;
            justify-content: center;
        }
        .table__Image2 {
            border-top: 0.5rem solid transparent;
            width: 25%;
            margin: auto;
            display: flex;
            justify-content: center;
        }
    }
   
}