
@media screen and (max-width: 500px) {
    .style {
        background-image: linear-gradient(60deg, #e21143, #ffb03a);
        background-clip: text;
        color: transparent;
    }

    .fixed-navbar {
        z-index: 10; 
    }

    .dish {
        width: 90%;
        margin: auto;
        padding-top: 0.1rem;
        animation: menu-item 0.5s ease-in-out;

        &__image__holder {
            width: 12rem;
            height: 12rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 1.3rem auto 1rem;
            display: flex;
            justify-content: center;
        }
        &__image {
            width: 11.5rem;
            height: 11.5rem;
            display: flex;
            border-radius: 10px;
            margin: 0rem auto 0rem;
        }
        &__text {
            text-align: center;
            margin: 0.9rem 0rem;
        }
        &__name {
            font-family: $Caterina;
            font-weight: 800;
            letter-spacing: 1px;
            line-height: 27px;
            font-size: 24px;
            margin: 0rem 0rem 0.5rem;
            text-transform: capitalize;
        }
        &__descriptions {
            color: #2d2d2d;
            font-weight: lighter;
            font-size: 16px;
            line-height: 18px;
            font-family: $ProximaReg;
        }
        &__price {
            font-family: $ProximaReg;
            line-height: 25px;
            font-size: 18px;
            margin: 0.8rem 0rem;
        }
        &__purchase {
            width: 90%;
            display: flex;
            justify-content: space-evenly;
            margin: 1.5rem auto 1.7rem;
        }
        &__calculator {
            background-color: #000;
            height: 3rem;
            width: 40%;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
        }

        &__calc {
            font-family: $ProximaReg;
            color: #fff;
            width: 33%;
            padding-top: 7px;
            font-size: 28px;
            text-align: center;
            height: 100%;
            margin: auto;
        }
    }
}

// medium sized tablet

@media screen and (max-width: 800px) and (min-width: 501px) {
    .style{
        background-image: linear-gradient(60deg, #e21143, #ffb03a);
        background-clip: text;
        color: transparent;
    }
    .fixed-navbar {
        z-index: 10;
    }

    .dish {
        width: 80%;
        margin: auto;
        animation: menu-item 0.5s ease-in-out;
        &__image__holder {
            width: 16rem;
            height: 16rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 1rem auto;
            display: flex;
            justify-content: center;
        }
        &__image {
            width: 11rem;
            height: 11rem;
            display: flex;
            border-radius: 10px;
            margin: 2.4rem auto 0rem;
        }
        &__text {
            text-align: center;
        }
        &__name {
            font-family: $Caterina;
            font-weight: 800;
            letter-spacing: 1px;
            line-height: 40px;
            font-size: 30px;
            margin: 0rem 0rem 0.5rem;
            text-transform: capitalize;
        }
        &__descriptions {
            color: #2d2d2d;
            font-weight: lighter;
            font-size: 18px;
            line-height: 20px;
            font-family: $ProximaReg;
        }
        &__price {
            font-family: $ProximaReg;
            line-height: 30px;
            font-size: 22px;
            margin: 0.8rem 0rem;
        }
        &__purchase {
            width: 90%;
            display: flex;
            justify-content: space-evenly;
            margin: 1.5rem auto 1.7rem;
        }
        &__calculator {
            background-color: #000;
            height: 3rem;
            width: 40%;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
        }

        &__calc {
            font-family: $ProximaReg;
            color: #fff;
            width: 33%;
            padding-top: 9px;
            font-size: 28px;
            text-align: center;
            height: 100%;
            margin: auto;
            transition: 4s ease-in;
        }
    }

}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
    .style{
        background-image: linear-gradient(60deg, #e21143, #ffb03a);
        background-clip: text;
        color: transparent;
    }

    .fixed-navbar {
        z-index: 10; 
    }

    .dish {
        width: 50%;
        margin: auto;

        animation: menu-item 0.5s ease-in-out;
        &__image__holder {
            width: 20rem;
            height: 20rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 1rem auto;
            display: flex;
            justify-content: center;
        }
        &__image {
            width: 16rem;
            height: 16rem;
            display: flex;
            border-radius: 10px;
            margin: 3rem auto 0rem;
        }
        &__text {
            text-align: center;
            width: 100%;
        }
        &__name {
            font-family: $Caterina;
            font-weight: 800;
            letter-spacing: 1px;
            line-height: 40px;
            font-size: 32px;
            margin: 0rem 0rem 0.5rem;
            text-transform: capitalize;
        }
        &__descriptions {
            color: #2d2d2d;
            font-weight: lighter;
            font-size: 22px;
            line-height: 25px;
            font-family: $ProximaReg;
        }
        &__price {
            font-family: $ProximaReg;
            line-height: 35px;
            font-size: 20px;
            margin: 0.8rem 0rem;
            font-weight: 600;
        }
        &__purchase {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            margin: 1.5rem auto 1.7rem;
        }
        &__calculator {
            background-color: #000;
            height: 3rem;
            width: 40%;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
        }

        &__calc {
            font-family: $ProximaReg;
            color: #fff;
            width: 33%;
            padding-top: 9px;
            font-size: 28px;
            text-align: center;
            height: 100%;
            margin: auto;
            transition: 4s ease-in;
        }
        &__select {

        }
    }

}
@media screen and (min-device-width: 1280px) {
    .style {
        background-image: linear-gradient(60deg, #e21143, #ffb03a);
        background-clip: text;
        color: transparent;
    }

    .fixed-navbar {
        z-index: 10; 
    }

    .dish {
        width: 40%;
        margin: auto;
        padding-top: 0.1rem;
        animation: menu-item 0.5s ease-in-out;

        &__image__holder {
            width: 14rem;
            height: 14rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 0rem auto 1rem;
            display: flex;
            justify-content: center;
        }
        &__image {
            width: 9.5rem;
            height: 9.5rem;
            display: flex;
            border-radius: 10px;
            margin: 2rem auto 0rem;
        }
        &__text {
            text-align: center;
            margin: 0.9rem 0rem;
        }
        &__name {
            font-family: $Caterina;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 27px;
            font-size: 24px;
            margin: 0rem 0rem 0.5rem;
            text-transform: capitalize;
        }
        &__descriptions {
            color: #2d2d2d;
            font-weight: lighter;
            font-size: 14px;
            line-height: 16px;
            font-family: $ProximaReg;
        }
        &__price {
            font-family: $ProximaReg;
            line-height: 25px;
            font-size: 18px;
            margin: 0.8rem 0rem;
        }
        &__purchase {
            width: 90%;
            display: flex;
            justify-content: space-evenly;
            margin: 1.5rem auto 1.7rem;
        }
        &__calculator {
            background-color: #000;
            height: 3rem;
            width: 40%;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
        }

        &__calc {
            color: #fff;
            width: 33%;
            padding-top: 9px;
            font-size: 28px;
            text-align: center;
            height: 100%;
            margin: auto;
            transition: 4s ease-in;
            font-family: $ProximaReg;
        }
    }
}

@keyframes menu-item {
    from {
        opacity: .3;
    }
    to {
        opacity: 1;
    }
}


