* {
    padding: 0;
    margin: 0;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Caterina";
  src: url("../fonts/Caterina.ttf") format("truetype");
}
@font-face {
  font-family: "Proxima Nova Cn Rg";
  src: url("../fonts/ProximaNovaCond-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova Cn Rg";
  src: url("../fonts/ProximaNovaCond-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Mansalva";
  src: url("../fonts/MansalvaRegular.ttf") format("truetype");
}
@import 'fonts';
@import 'bills';
@import 'menu';
@import 'final';
@import 'modal';
@import 'table';
@import 'payment';

$Catherina:"Caterina";
$black: #121212;

.CatherinaFont{
    font-family:$Catherina;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.container {
    width: 90%;
    margin: auto;
}
@media screen and (max-width: 500px) {
    .loading__ {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; 
        width: 100%;
    }
    .waiting__loader {
        width: 25vw;
        height: 10rem;
        margin: auto;
    }

    .header {
        text-transform: capitalize;
        font-family: $Caterina;
        width: 100%;
        text-align: center;
        height: 3rem;
        font-size: 30px;

        h2 {
            font-size: 25px;
            font-family: $Caterina;
            font-weight:800;
        }
    }
    .home__header {
        width: 100%;
        height: 42vh;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('../images/IMG_9094.JPG');
        mask-image: linear-gradient(to top, transparent 10%, white 50%);
        animation: home__animation 1s;
        margin-bottom: 3rem;
    }
    .mask {
        background-color: #fff;
        width:100%;
        height:6rem;
        mask-image: linear-gradient(to bottom, transparent 10%, rgba(255, 255, 255) 80%);
        animation: home__animation 1s;
    }

    .welcome__main {
        width: 90%;
        margin: 1vh auto 2vh;
        background-color: #fff;
        color: $black;
        animation: home__animation 1s;
        height: 45vh;
    }
    .welcome__title {
        font-weight: 800;
        font-family: $Caterina;
        text-transform: capitalize;
        font-size: 44px;
        line-height: 50px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        letter-spacing: 1px;
        width: 100%;
    }
    .welcome__extras {
        font-weight: 300;
        line-height: 18px;
        text-transform: none;
        font-family: $ProximaReg;
    }
    .welcome__article {
        width: 100%;
        margin-top: 1rem;
    }
    .welcome__tableBill {
        width: 100%;
        height: 4em;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.2rem;
        margin-top: 1rem;
        align-items: center;
    }
    .welcome__tableText1 {
        color: $black;
        font-size: 20px;
        line-height: 20px;
        font-family: $ProximaReg;
    }
    .welcome__tableText2 {
        color: $black;
        font-size: 22px;
        line-height: 23px;
        font-family: $ProximaReg;
    }

    .end {
        text-align: end;
    }
    .start {
        text-align: start;
    }
    .art {
        display: flex;
        height: 1rem;
        align-self: center;
        margin: 0rem auto;
        width: 80%;
        justify-content: space-between;
        &__circle {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: #cfcfcf;
            margin: -1.2rem 0rem;
        }
        &__line {
            background-color: #cfcfcf;
            width: 45%;
            height: 1px;
            margin: -1rem 0rem;
        }
    }
    .footer {
        width: 100%;
        display: flex;
        height: 5vh;
        justify-content: center;
        h3 {
            margin-left: 1.5rem;
            font-family: $Caterina;
            font-size: 14px;
            font-weight: 400;
        }
        img {
            margin-top: -0.5rem;
            margin-left: 0.4rem;
            width: 5.2rem;
            height: 1.8rem;
        }
    }
    .foot {
        width: 100%;
        height: 5vh;
        display: flex;
        justify-content: center;
        h3 {
            padding-top: 2vh;
            margin-left: 1.5rem;
            font-family: $Caterina;
            font-size: 14px;
            font-weight: 400;
        }
        img {
            padding-top: 2vh;
            margin-top: -0.5rem;
            margin-left: 0.4rem;
            width: 5.2rem;
            height: 1.8rem;
        }
    }
    .icon__trash {
        color: red;
    }
    .icon__back {
        font-size: 34px;

        font-weight: 900;
    }
    .icon__modal {
        font-size: 34px;
        margin-top: 7px;
        margin-left: 25px;
    }
    .icon__bill {
        margin-left: 15px;
        margin-top: 8px;
    }
    .icon__home {
        font-size: 22px;
        margin-top: 8px;
    }
    .icon__sign {
        font-size: 21px;
    }
    .icon__scroll--right {
        width: 20px;
        margin-left: 15px;
    }
    .icon__scroll--left {
        width: 20px;
        margin-right: 15px;
    }
}

@media screen and (min-width: 501px) and (max-width: 800px) {
    .loading__page {
        width: 100%;
        min-height: 100vh;
        background: url('../images/menu-background.png');
    }
    .mask {
        background-color: #fff;
        width:100%;
        height:6rem;
        mask-image: linear-gradient(to bottom, transparent 10%, rgba(255, 255, 255) 80%);
        animation: home__animation 1s;
    }

    .loading__spinner {
        margin: auto;
        padding-top: 20vh;
    }
    .loading__ {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; 
        width: 100%;
    }
    .header {
        text-transform: capitalize;
        font-family: $Caterina;
        width: 100%;
        text-align: center;
        height: 3rem;
        font-size: 30px;
    }
    .home__header {
        width: 100%;
        height: 43vh;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('../images/IMG_9094.JPG');
        mask-image: linear-gradient(to top, transparent 10%, white 50%);
        animation: home__animation 1s;
    }
    .container {
        width: 90%;
        margin: auto;
    }

    .welcome__main {
        width: 85%;
        height: 50vh;
        margin: 1vh auto 2vh;
        background-color: #fff;
        color: $black;
        animation: home__animation 1s;
    }
    .welcome__title {
        font-weight: 600;
        font-family: $Caterina;
        text-transform: capitalize;
        font-size: 44px;
        line-height: 26px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        letter-spacing: 1px;
        width: 100%;
    }
    .welcome__extras {
        font-weight: 300;
        line-height: 22px;
        text-transform: none;
        font-size: 20px;
        font-family: $ProximaReg;
    }
    .welcome__article {
        width: 100%;
        margin-top: 1rem;
    }
    .welcome__tableBill {
        width: 100%;
        height: 4em;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        margin-top: 0rem;
        align-items: center;
    }
    .welcome__tableText1 {
        color: $black;
        font-size: 20px;
        line-height: 20px;
    }
    .welcome__tableText2 {
        color: $black;
        font-size: 22px;
        line-height: 23px;
    }

    .end {
        text-align: end;
    }
    .start {
        text-align: start;
    }
    .art {
        display: flex;
        height: 1rem;
        align-self: center;
        margin: 0rem auto;
        width: 80%;
        justify-content: space-between;
        &__circle {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: #cfcfcf;
            margin: -1.2rem 0rem;
        }
        &__line {
            background-color: #cfcfcf;
            width: 45%;
            height: 1px;
            margin: -1rem 0rem;
        }
    }
    .footer {
        width: 100%;
        display: flex;
        height: 5vh;
        justify-content: center;
        h3 {
            margin-left: 1.5rem;
            font-style: $Caterina;
            font-size: 14px;
            font-weight: 400;
        }
        img {
            margin-top: -0.5rem;
            margin-left: 0.4rem;
            width: 5.2rem;
            height: 1.8rem;
        }
    }
    .foot {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 5vh;
        h3 {
            margin-left: 1.5rem;
            font-style: $Caterina;
            font-size: 14px;
            font-weight: 400;
        }
        img {
            margin-top: -0.5rem;
            margin-left: 0.4rem;
            width: 5.2rem;
            height: 1.8rem;
        }
    }

    .icon__trash {
        color: red;
    }
    .icon__back {
        font-size: 24px;
        margin-top: 4px;
    }
    .icon__modal {
        font-size: 34px;
        margin-top: 7px;
        margin-left: 25px;
    }
    .icon__bill {
        margin-left: 15px;
        margin-top: 8px;
    }
    .icon__home {
        font-size: 22px;
        margin-top: 8px;
    }
    .icon__sign {
        font-size: 21px;
    }
    .icon__scroll--right {
        width: 20px;
        margin-top: 5px;
        margin-left: 15px;
    }
    .icon__scroll--left {
        width: 20px;
        margin-top: 5px;
        margin-right: 15px;
    }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
    .loading__page {
        width: 100%;
        min-height: 100vh;
        background: url('../images/menu-background.png');
    }
    .mask {
        background-color: #fff;
        width:100%;
        height:6rem;
        mask-image: linear-gradient(to bottom, transparent 100%, rgba(255, 255, 255) 100%);
        animation: home__animation 1s;
    }

    .loading__spinner {
        margin: auto;
        padding-top: 20vh;
    }
    .loading__ {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; 
        width: 100%;
    }
    .header {
        text-transform: capitalize;
        font-family: $Caterina;
        width: 100%;
        text-align: center;
        height: 3rem;
        font-size: 30px;
    }
    .home__header {
        width: 100%;
        height: 40vh;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('../images/IMG_9094.JPG');
        mask-image: linear-gradient(to top, transparent 10%, white 50%);
        animation: home__animation 1s;
    }
    .container {
        width: 90%;
        margin: auto;
    }

    .welcome__main {
        width: 90%;
        margin: 1rem auto 2rem;
        background-color: #fff;
        color: $black;
        height: 50vh;
        animation: home__animation 1s;
    }
    .welcome__title {
        font-weight: 600;
        font-family: $Caterina;
        text-transform: capitalize;
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 1rem;
        letter-spacing: 1px;
        width: 100%;
    }
    .welcome__extras {
        font-weight: 300;
        line-height: 20px;
        text-transform: none;
        font-size: 20px;
        font-family: $ProximaReg;
    }
    .welcome__article {
        width: 100%;
        margin-top: 1rem;
    }
    .welcome__tableBill {
        width: 100%;
        height: 5em;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0rem;
        margin-top: 0.5em;
        align-items: center;
    }
    .welcome__tableText1 {
        color: $black;
        font-size: 20px;
        line-height: 24px;
        font-family: $ProximaReg;
    }
    .welcome__tableText2 {
        color: $black;
        font-size: 22px;
        font-family: $ProximaReg;
        line-height: 23px;
    }

    .end {
        text-align: end;
    }
    .start {
        text-align: start;
    }
    .art {
        display: flex;
        height: 1rem;
        align-self: center;
        margin: 0rem auto;
        width: 80%;
        justify-content: space-between;
        &__circle {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: #cfcfcf;
            margin: -1.2rem 0rem;
        }
        &__line {
            background-color: #cfcfcf;
            width: 45%;
            height: 1px;
            margin: -1rem 0rem;
        }
    }
    .footer {
        width: 100%;
        display: flex;
        height: 5vh;
        justify-content: center;
        h3 {
            margin-left: 1.5rem;
            font-style: $Caterina;
            font-size: 14px;
            font-weight: 400;
        }
        img {
            margin-top: -0.5rem;
            margin-left: 0.4rem;
            width: 5.2rem;
            height: 1.8rem;
        }
    }
    .foot {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 5vh;
        h3 {
            margin-left: 1.5rem;
            font-style: $Caterina;
            font-size: 14px;
            font-weight: 400;
        }
        img {
            margin-top: -0.5rem;
            margin-left: 0.4rem;
            width: 5.2rem;
            height: 1.8rem;
        }
    }

    .icon__trash {
        color: red;
    }
    .icon__back {
        font-size: 24px;
        margin-top: 4px;
    }
    .icon__modal {
        font-size: 34px;
        margin-top: 7px;
        margin-left: 25px;
    }
    .icon__bill {
        margin-left: 15px;
        margin-top: 8px;
    }
    .icon__home {
        font-size: 22px;
        margin-top: 8px;
    }
    .icon__sign {
        font-size: 21px;
    }
    .icon__scroll {
        width: 20px;
        margin-left: 15px;
    }
    .icon__scroll--left {
        display: none;
    } 
    .icon__scroll--right {
        display: none;
    } 
}
@media screen and (min-device-width: 1280px) {
    .error {
        width: 100%;
        min-height: 100vh;
        background: url('../images/menu-background.png');
    }
    .error__image {
        width: 30%;
        height: 4rem;
        padding-top: 10vh;
        margin: auto;
        h2 {
            text-align: center;
            text-transform: capitalize;
            font-weight: 800;
            letter-spacing: 2px;
            font-size: 50px;
            line-height: 40px;
            margin: 2rem auto;
            font-family: $Caterina;
        }
        img {
            width: 100%;
            border-radius: 5%;
        }
        p {
            margin: 2rem auto;
            text-align: center;
            font-size: 20px;
            line-height: 20px;
        }
    }

    .loading__page {
        width: 100%;
        min-height: 100vh;
        background: url('../images/menu-background.png');
    }
    .loading__spinner {
        margin: auto;
        padding-top: 20vh;
    }
    .loading__ {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; 
        width: 100%;
    }

    .header {
        text-transform: capitalize;
        font-family: $Caterina;
        width: 100%;
        text-align: center;
        height: 3rem;
        font-size: 30px;

        h2 {
            font-size: 25px;
            font-family: $Caterina;
            font-weight: bold;
        }
    }
    .home__header {
        width: 100%;
        height: 75vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('../images/IMG_9094.JPG');
        mask-image: linear-gradient(to top, transparent 10%, white 50%);
        animation: home__animation 1s;
    }

    .container {
        width: 90%;
        margin: auto;
    }

    .welcome__main {
        width: 90%;
        margin: 1vh auto 2vh;
        background-color: #fff;
        color: $black;
        animation: home__animation 1s;
        height: 45vh;
    }
    .welcome__title {
        font-weight: 600;
        font-family: $Caterina;
        text-transform: capitalize;
        font-size: 44px;
        line-height: 50px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        letter-spacing: 1px;
        width: 100%;
        text-align: center;
    }
    .welcome__extras {
        font-weight: 300;
        line-height: 18px;
        text-transform: none;
        text-align: center;
        font-family: $ProximaReg;
    }
    .welcome__article {
        width: 100%;
        margin-top: 1rem;
    }
    .welcome__tableBill {
        width: 100%;
        height: 4em;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.2rem;
        margin-top: 1rem;
        align-items: center;
        font-family: $ProximaReg;
    }
    .welcome__tableText1 {
        color: $black;
        font-size: 20px;
        line-height: 20px;
        font-family: $ProximaReg;
    }
    .welcome__tableText2 {
        color: $black;
        font-size: 22px;
        line-height: 23px;
        font-family: $ProximaReg;
    }

    .end {
        text-align: end;
    }
    .start {
        text-align: start;
    }
    .art {
        display: flex;
        height: 1rem;
        align-self: center;
        margin: 0rem auto;
        width: 80%;
        justify-content: space-between;
        &__circle {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: #cfcfcf;
            margin: -1.2rem 0rem;
        }
        &__line {
            background-color: #cfcfcf;
            width: 45%;
            height: 1px;
            margin: -1rem 0rem;
        }
    }
    .footer {
        width: 100%;
        display: flex;
        height: 5vh;
        justify-content: center;
        h3 {
            margin-left: 1.5rem;
            font-style: $Caterina;
            font-size: 14px;
            font-weight: 400;
        }
        img {
            margin-top: -0.5rem;
            margin-left: 0.4rem;
            width: 5.2rem;
            height: 1.8rem;
        }
    }
    .foot {
        width: 100%;
        height: 5vh;
        display: flex;
        justify-content: center;
        h3 {
            padding-top: 2vh;
            margin-left: 1.5rem;
            font-style: $Caterina;
            font-size: 14px;
            font-weight: 400;
        }
        img {
            padding-top: 2vh;
            margin-top: -0.5rem;
            margin-left: 0.4rem;
            width: 5.2rem;
            height: 1.8rem;
        }
    }
    .icon__trash {
        color: red;
    }
    .icon__back {
        font-size: 24px;
        margin-top: 4px;
    }
    .icon__modal {
        font-size: 34px;
        margin-top: 7px;
        margin-left: 25px;
    }
    .icon__bill {
        margin-left: 15px;
        margin-top: 8px;
    }
    .icon__home {
        font-size: 22px;
        margin-top: 8px;
    }
    .icon__sign {
        font-size: 21px;
    }
    .icon__scroll--right {
        display: none;
        width: 20px;
        margin-left: 15px;
    }
    .icon__scroll--left {
        display: none;
        width: 20px;
        margin-right: 15px;
    }
}
@keyframes home__main__animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes home__animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
