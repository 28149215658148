@media screen and (max-width: 500px) {
    .modal,
    .overlay__black {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        animation: ease-in 0.2s Overtlay;
    }

    .overlay__black {
        background: rgba(0, 0, 0, 0.4);
    }
    .modal,
    .overlay__white {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        animation: ease-in 0.2s Overtlay;
    }

    .overlay__white {
        background: #fff;
    }
    .modal__input {
        width: 100%;
        margin: 1rem auto 0rem;
        height: 3.5rem;
        font-family: $ProximaReg;
        font-size: 36px;
        font-weight: bold;

        & div {
            display: flex;
            justify-content: center;
        }

        & div .input__split {
            border: transparent solid 0px;
            width: 80%;
            margin: auto;
            font-size: 36px;
            font-weight: 600;
            font-family: $ProximaReg;
            text-align: center;
        }
        & div input:focus {
            outline: none;
            text-align: center;
        }
        img {
            margin: -0.5rem auto 0rem;
            display: flex;
            width: 6rem;
            justify-content: center;
        }
        .modal__design {
            border-bottom: rgb(43, 185, 178) solid 2px;
            width: 20%;
            height: 1rem;
            margin: -0.6rem auto;
        }
    }
    .label{
        animation: ease-in 1s LoginLabel;   
    }
}

@media screen and (min-width: 501px) and (max-width: 850px) {
    .modal,
    .overlay__black {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        animation: ease-in 0.2s Overtlay;
    }
    .overlay__white {
        background: #fff;
    }
    .modal,
    .overlay__white {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        animation: ease-in 0.2s Overtlay;
    }
    .overlay__black {
        background: rgba(0, 0, 0, 0.4);
    }
    .modal__input {
        width: 100%;
        margin: 1rem auto 0rem;
        height: 3.5rem;
        font-family: $ProximaReg;
        font-size: 36px;
        font-weight: bold;

        & div {
            display: flex;
            justify-content: center;
        }

        & div .input__split {
            border: transparent solid 0px;
            width: 80%;
            margin: auto;
            font-size: 36px;
            font-weight: 600;
            font-family: $ProximaReg;
            text-align: center;
        }
        & div input:focus {
            outline: none;
            text-align: center;
        }
        img {
            margin: -0.5rem auto 0rem;
            display: flex;
            width: 6rem;
            justify-content: center;
        }
        .modal__design {
            border-bottom: rgb(43, 185, 178) solid 2px;
            width: 20%;
            height: 1rem;
            margin: -0.6rem auto;
        }
    }
    .label{
        animation: ease-in 3s LoginLabel;   
    }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
    .modal,
    .overlay__black{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        animation: ease-in 0.1s Overtlay;
    }

    .overlay__black {
        background: rgba(0, 0, 0, 0.4);
    }
    .modal,
    .overlay__white{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        animation: ease-in 0.1s Overtlay;
    }

    .overlay__white {
        background: #fff;
    }
    .modal__input {
        width: 100%;
        margin: 1rem auto 0rem;
        height: 3.5rem;
        font-family: $ProximaReg;
        font-size: 36px;
        font-weight: bold;

        & div {
            display: flex;
            justify-content: center;
        }

        & div .input__split {
            border: transparent solid 0px;
            width: 80%;
            margin: auto;
            font-size: 36px;
            font-weight: 600;
            font-family: $ProximaReg;
            text-align: center;
        }
        & div input:focus {
            outline: none;
            text-align: center;
        }
        img {
            margin: -0.5rem auto 0rem;
            display: flex;
            width: 6rem;
            justify-content: center;
        }
        .modal__design {
            border-bottom: rgb(43, 185, 178) solid 2px;
            width: 20%;
            height: 1rem;
            margin: -0.6rem auto;
        }
    }
    .label{
        animation: ease-in 1s LoginLabel;   
    }
}
@media screen and (min-device-width: 1280px) {
    .modal,
    .overlay__black{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        animation: ease-in 0.2s Overtlay;
    }
    .overlay__black {
        background: rgba(0, 0, 0, 0.4);
    }
    .modal,
    .overlay__white{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        animation: ease-in 0.1s Overtlay;
    }

    .overlay__white {
        background: #fff;
    }
    .modal__input {
        width: 100%;
        margin: 1rem auto 0rem;
        height: 3.5rem;
        font-family: $ProximaReg;
        font-size: 36px;
        font-weight: bold;

        & div {
            display: flex;
            justify-content: center;
        }

        & div .input__split {
            border: transparent solid 0px;
            width: 80%;
            margin: auto;
            font-size: 36px;
            font-weight: 600;
            font-family: $ProximaReg;
            text-align: center;
        }
        & div input:focus {
            outline: none;
            text-align: center;
        }
        img {
            margin: -0.5rem auto 0rem;
            display: flex;
            width: 6rem;
            justify-content: center;
        }
        .modal__design {
            border-bottom: rgb(43, 185, 178) solid 2px;
            width: 20%;
            height: 1rem;
            margin: -0.6rem auto;
        }
    }
    .label{
        animation: ease-in 1s LoginLabel;   
    }
}


@keyframes Overtlay {
    from {
        // opacity:0;
        height: 200%;
    }
    to {
        // opacity:1;
        height: 100%;
    }
}

@keyframes LoginLabel {
    from {
        height:0%;
        opacity: 0;
    }    
    to{
        height:100%;
        opacity: 1;
    }
}
