$Caterina: 'Caterina', sans-serif;
$ProximaReg:   'Proxima Nova Cn Rg';
$ProximaBold: 'Proxima Nova Cn Rg';
$Manslava: 'Mansalva';
@font-face {
    font-family: $Caterina;
    src: url('../fonts/Caterina.ttf') format('truetype');

}
@font-face {
    font-family: $ProximaReg;
    src: url('../fonts/ProximaNovaCond-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
    
@font-face {
    font-family: $ProximaBold;
    src: url('../fonts/ProximaNovaCond-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: $Manslava;
    src: url('../fonts/MansalvaRegular.ttf');
    font-style: italic;
}