@media screen and (max-width: 500px) {
    .spacing__words{
    letter-spacing: 8px;
}
}
@media (min-device-width: 501px) and (max-device-width: 800px) {
    .spacing__words{
        letter-spacing: 8px;
    }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
    .spacing__words{
        letter-spacing: 8px;
    }
}
@media screen and (min-device-width: 1280px) {
    .spacing__words{
        letter-spacing: 8px;
    }
}
