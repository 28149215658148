@media screen and (max-width: 500px) {
    .main__final {
        width: 100%;
    }
    .final__image {
        animation: design 3s;
        width: 20rem;
        margin: auto;
    }
    .final__section {
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .final__illustration {
        width: 100%;
        margin: 2rem auto;
        animation: final_page 1s;

        h2 {
            color: rgb(37, 214, 37);
            text-align: center;
            transform: rotate(-6deg);
        }
    }
    .final__giphy {
        transform: rotate(1deg);
        width: 70%;
        margin: 2rem auto;
        justify-content: center;
        display: flex;
        height: 15rem;
        animation-name: gif;
        animation-duration: 2s;
    }
}
@media screen and (min-width: 501px) and (max-width: 800px) {
    .main__final {
        width: 100%;
    }
    .final__image {
        animation: design 3s;
        width: 40rem;
        margin: auto;
    }
    .final__section {
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .final__illustration {
        width: 100%;
        margin: 2rem auto;
        animation: final_page 1s;

        h2 {
            color: rgb(37, 214, 37);
            text-align: center;
            transform: rotate(-6deg);
        }
    }
    .final__giphy {
        transform: rotate(1deg);
        width: 70%;
        margin: 2rem auto;
        justify-content: center;
        display: flex;
        height: 15rem;
        animation-name: gif;
        animation-duration: 2s;
    }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
    .main__final {
        width: 100%;
    }
    .final__image {
        animation: design 3s;
        width: 40rem;
        margin: auto;
    }
    .final__section {
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .final__illustration {
        width: 100%;
        margin: 2rem auto;
        animation: final_page 1s;

        h2 {
            color: rgb(37, 214, 37);
            text-align: center;
            transform: rotate(-6deg);
        }
    }
    .final__giphy {
        transform: rotate(1deg);
        width: 70%;
        margin: 2rem auto;
        justify-content: center;
        display: flex;
        height: 25rem;
        animation-name: gif;
        animation-duration: 2s;
    }
}
@media screen and (min-device-width: 1280px) {
    .main__final {
        width: 100%;
    }
    .final__image {
        animation: design 3s;
        width: 20rem;
        margin: auto;
    }
    .final__section {
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .final__illustration {
        width: 100%;
        margin: 2rem auto;
        animation: final_page 1s;

        h2 {
            color: rgb(37, 214, 37);
            text-align: center;
            transform: rotate(-6deg);
            font-family: $ProximaBold;
        }
    }
    .final__giphy {
        transform: rotate(1deg);
        width: 70%;
        margin: 2rem auto;
        justify-content: center;
        display: flex;
        height: 20rem;
        animation-name: gif;
        animation-duration: 2s;
    }
}
@keyframes design {
    0% {
        opacity: 0;
        transform: rotate(-350deg);
    }
    50% {
        opacity: 0.75;
        transform: rotate(40deg);
    }
    75% {
        opacity: 1;
        transform: rotate(0deg);
    }
    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}
@keyframes gif {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes final_page {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}
