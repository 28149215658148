@media screen and (max-width: 500px) {
    .icon {
        margin-top: 3rem;
        padding: 0rem 0.4rem;
    }
    .bill__section {
        display: flex;
    }
    .bill__tip-option {
        min-height: 8rem;
        display: flex;
        font-family: $ProximaReg;
        overflow-x: auto;
        overscroll-behavior-inline: contain;
        margin: 0.5rem 0rem;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
@media screen and (min-width: 501px) and (max-width: 800px) {
    .icon {
        display: none;
    }
    .bill__section {
        display: flex;
    }
    .bill__tip-option {
        min-height: 12rem;
        display: flex;
        justify-content: space-between;
        font-family: $ProximaReg;
        margin: 0.5rem 0rem;
        width: 100%;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
       .bill__section {
        display: flex;
    }
    .bill__tip-option {
        min-height: 11rem;
        display: flex;
        font-family: $ProximaReg;
        width: 100%;
        justify-content: space-between;
        margin: 0.5rem auto;
         }
}
@media screen and (min-device-width:1280px) {
    .arrowState {
        display: none;
    }
    .icon {
        margin-top: 3rem;
        padding: 0rem 0.4rem;
    }
   

    .bill__section {
        display: flex;
    }
    .bill__tip-option {
        min-height: 8rem;
        display: flex;
        font-family: $ProximaReg;
        justify-content: center;
        width: 100%;
        margin: 0.5rem 0rem;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
