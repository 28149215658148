* {
  padding: 0;
  margin: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Caterina";
  src: url("../fonts/Caterina.ttf") format("truetype");
}
@font-face {
  font-family: "Proxima Nova Cn Rg";
  src: url("../fonts/ProximaNovaCond-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova Cn Rg";
  src: url("../fonts/ProximaNovaCond-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Mansalva";
  src: url("../fonts/MansalvaRegular.ttf") format("truetype");
}
@font-face {
  font-family: "Caterina", sans-serif;
  src: url("../fonts/Caterina.ttf") format("truetype");
}
@font-face {
  font-family: "Proxima Nova Cn Rg";
  src: url("../fonts/ProximaNovaCond-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova Cn Rg";
  src: url("../fonts/ProximaNovaCond-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Mansalva";
  src: url("../fonts/MansalvaRegular.ttf");
  font-style: italic;
}
@media screen and (max-width: 500px) {
  .icon {
    margin-top: 3rem;
    padding: 0rem 0.4rem;
  }
  .bill__section {
    display: flex;
  }
  .bill__tip-option {
    min-height: 8rem;
    display: flex;
    font-family: "Proxima Nova Cn Rg";
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    margin: 0.5rem 0rem;
  }
  .bill__tip-option::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (min-width: 501px) and (max-width: 800px) {
  .icon {
    display: none;
  }
  .bill__section {
    display: flex;
  }
  .bill__tip-option {
    min-height: 12rem;
    display: flex;
    justify-content: space-between;
    font-family: "Proxima Nova Cn Rg";
    margin: 0.5rem 0rem;
    width: 100%;
  }
  .bill__tip-option::-webkit-scrollbar {
    display: none;
  }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
  .bill__section {
    display: flex;
  }
  .bill__tip-option {
    min-height: 11rem;
    display: flex;
    font-family: "Proxima Nova Cn Rg";
    width: 100%;
    justify-content: space-between;
    margin: 0.5rem auto;
  }
}
@media screen and (min-device-width: 1280px) {
  .arrowState {
    display: none;
  }
  .icon {
    margin-top: 3rem;
    padding: 0rem 0.4rem;
  }
  .bill__section {
    display: flex;
  }
  .bill__tip-option {
    min-height: 8rem;
    display: flex;
    font-family: "Proxima Nova Cn Rg";
    justify-content: center;
    width: 100%;
    margin: 0.5rem 0rem;
  }
  .bill__tip-option::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .style {
    background-image: linear-gradient(60deg, #e21143, #ffb03a);
    -webkit-background-clip: text;
            background-clip: text;
    color: transparent;
  }
  .fixed-navbar {
    z-index: 10;
  }
  .dish {
    width: 90%;
    margin: auto;
    padding-top: 0.1rem;
    animation: menu-item 0.5s ease-in-out;
  }
  .dish__image__holder {
    width: 12rem;
    height: 12rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 1.3rem auto 1rem;
    display: flex;
    justify-content: center;
  }
  .dish__image {
    width: 11.5rem;
    height: 11.5rem;
    display: flex;
    border-radius: 10px;
    margin: 0rem auto 0rem;
  }
  .dish__text {
    text-align: center;
    margin: 0.9rem 0rem;
  }
  .dish__name {
    font-family: "Caterina", sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 27px;
    font-size: 24px;
    margin: 0rem 0rem 0.5rem;
    text-transform: capitalize;
  }
  .dish__descriptions {
    color: #2d2d2d;
    font-weight: lighter;
    font-size: 16px;
    line-height: 18px;
    font-family: "Proxima Nova Cn Rg";
  }
  .dish__price {
    font-family: "Proxima Nova Cn Rg";
    line-height: 25px;
    font-size: 18px;
    margin: 0.8rem 0rem;
  }
  .dish__purchase {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    margin: 1.5rem auto 1.7rem;
  }
  .dish__calculator {
    background-color: #000;
    height: 3rem;
    width: 40%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }
  .dish__calc {
    font-family: "Proxima Nova Cn Rg";
    color: #fff;
    width: 33%;
    padding-top: 7px;
    font-size: 28px;
    text-align: center;
    height: 100%;
    margin: auto;
  }
}
@media screen and (max-width: 800px) and (min-width: 501px) {
  .style {
    background-image: linear-gradient(60deg, #e21143, #ffb03a);
    -webkit-background-clip: text;
            background-clip: text;
    color: transparent;
  }
  .fixed-navbar {
    z-index: 10;
  }
  .dish {
    width: 80%;
    margin: auto;
    animation: menu-item 0.5s ease-in-out;
  }
  .dish__image__holder {
    width: 16rem;
    height: 16rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 1rem auto;
    display: flex;
    justify-content: center;
  }
  .dish__image {
    width: 11rem;
    height: 11rem;
    display: flex;
    border-radius: 10px;
    margin: 2.4rem auto 0rem;
  }
  .dish__text {
    text-align: center;
  }
  .dish__name {
    font-family: "Caterina", sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 40px;
    font-size: 30px;
    margin: 0rem 0rem 0.5rem;
    text-transform: capitalize;
  }
  .dish__descriptions {
    color: #2d2d2d;
    font-weight: lighter;
    font-size: 18px;
    line-height: 20px;
    font-family: "Proxima Nova Cn Rg";
  }
  .dish__price {
    font-family: "Proxima Nova Cn Rg";
    line-height: 30px;
    font-size: 22px;
    margin: 0.8rem 0rem;
  }
  .dish__purchase {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    margin: 1.5rem auto 1.7rem;
  }
  .dish__calculator {
    background-color: #000;
    height: 3rem;
    width: 40%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }
  .dish__calc {
    font-family: "Proxima Nova Cn Rg";
    color: #fff;
    width: 33%;
    padding-top: 9px;
    font-size: 28px;
    text-align: center;
    height: 100%;
    margin: auto;
    transition: 4s ease-in;
  }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
  .style {
    background-image: linear-gradient(60deg, #e21143, #ffb03a);
    -webkit-background-clip: text;
            background-clip: text;
    color: transparent;
  }
  .fixed-navbar {
    z-index: 10;
  }
  .dish {
    width: 50%;
    margin: auto;
    animation: menu-item 0.5s ease-in-out;
  }
  .dish__image__holder {
    width: 20rem;
    height: 20rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 1rem auto;
    display: flex;
    justify-content: center;
  }
  .dish__image {
    width: 16rem;
    height: 16rem;
    display: flex;
    border-radius: 10px;
    margin: 3rem auto 0rem;
  }
  .dish__text {
    text-align: center;
    width: 100%;
  }
  .dish__name {
    font-family: "Caterina", sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 40px;
    font-size: 32px;
    margin: 0rem 0rem 0.5rem;
    text-transform: capitalize;
  }
  .dish__descriptions {
    color: #2d2d2d;
    font-weight: lighter;
    font-size: 22px;
    line-height: 25px;
    font-family: "Proxima Nova Cn Rg";
  }
  .dish__price {
    font-family: "Proxima Nova Cn Rg";
    line-height: 35px;
    font-size: 20px;
    margin: 0.8rem 0rem;
    font-weight: 600;
  }
  .dish__purchase {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 1.5rem auto 1.7rem;
  }
  .dish__calculator {
    background-color: #000;
    height: 3rem;
    width: 40%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }
  .dish__calc {
    font-family: "Proxima Nova Cn Rg";
    color: #fff;
    width: 33%;
    padding-top: 9px;
    font-size: 28px;
    text-align: center;
    height: 100%;
    margin: auto;
    transition: 4s ease-in;
  }
}
@media screen and (min-device-width: 1280px) {
  .style {
    background-image: linear-gradient(60deg, #e21143, #ffb03a);
    -webkit-background-clip: text;
            background-clip: text;
    color: transparent;
  }
  .fixed-navbar {
    z-index: 10;
  }
  .dish {
    width: 40%;
    margin: auto;
    padding-top: 0.1rem;
    animation: menu-item 0.5s ease-in-out;
  }
  .dish__image__holder {
    width: 14rem;
    height: 14rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0rem auto 1rem;
    display: flex;
    justify-content: center;
  }
  .dish__image {
    width: 9.5rem;
    height: 9.5rem;
    display: flex;
    border-radius: 10px;
    margin: 2rem auto 0rem;
  }
  .dish__text {
    text-align: center;
    margin: 0.9rem 0rem;
  }
  .dish__name {
    font-family: "Caterina", sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 27px;
    font-size: 24px;
    margin: 0rem 0rem 0.5rem;
    text-transform: capitalize;
  }
  .dish__descriptions {
    color: #2d2d2d;
    font-weight: lighter;
    font-size: 14px;
    line-height: 16px;
    font-family: "Proxima Nova Cn Rg";
  }
  .dish__price {
    font-family: "Proxima Nova Cn Rg";
    line-height: 25px;
    font-size: 18px;
    margin: 0.8rem 0rem;
  }
  .dish__purchase {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    margin: 1.5rem auto 1.7rem;
  }
  .dish__calculator {
    background-color: #000;
    height: 3rem;
    width: 40%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }
  .dish__calc {
    color: #fff;
    width: 33%;
    padding-top: 9px;
    font-size: 28px;
    text-align: center;
    height: 100%;
    margin: auto;
    transition: 4s ease-in;
    font-family: "Proxima Nova Cn Rg";
  }
}
@keyframes menu-item {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
@media screen and (max-width: 500px) {
  .main__final {
    width: 100%;
  }
  .final__image {
    animation: design 3s;
    width: 20rem;
    margin: auto;
  }
  .final__section {
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .final__illustration {
    width: 100%;
    margin: 2rem auto;
    animation: final_page 1s;
  }
  .final__illustration h2 {
    color: rgb(37, 214, 37);
    text-align: center;
    transform: rotate(-6deg);
  }
  .final__giphy {
    transform: rotate(1deg);
    width: 70%;
    margin: 2rem auto;
    justify-content: center;
    display: flex;
    height: 15rem;
    animation-name: gif;
    animation-duration: 2s;
  }
}
@media screen and (min-width: 501px) and (max-width: 800px) {
  .main__final {
    width: 100%;
  }
  .final__image {
    animation: design 3s;
    width: 40rem;
    margin: auto;
  }
  .final__section {
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .final__illustration {
    width: 100%;
    margin: 2rem auto;
    animation: final_page 1s;
  }
  .final__illustration h2 {
    color: rgb(37, 214, 37);
    text-align: center;
    transform: rotate(-6deg);
  }
  .final__giphy {
    transform: rotate(1deg);
    width: 70%;
    margin: 2rem auto;
    justify-content: center;
    display: flex;
    height: 15rem;
    animation-name: gif;
    animation-duration: 2s;
  }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
  .main__final {
    width: 100%;
  }
  .final__image {
    animation: design 3s;
    width: 40rem;
    margin: auto;
  }
  .final__section {
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .final__illustration {
    width: 100%;
    margin: 2rem auto;
    animation: final_page 1s;
  }
  .final__illustration h2 {
    color: rgb(37, 214, 37);
    text-align: center;
    transform: rotate(-6deg);
  }
  .final__giphy {
    transform: rotate(1deg);
    width: 70%;
    margin: 2rem auto;
    justify-content: center;
    display: flex;
    height: 25rem;
    animation-name: gif;
    animation-duration: 2s;
  }
}
@media screen and (min-device-width: 1280px) {
  .main__final {
    width: 100%;
  }
  .final__image {
    animation: design 3s;
    width: 20rem;
    margin: auto;
  }
  .final__section {
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .final__illustration {
    width: 100%;
    margin: 2rem auto;
    animation: final_page 1s;
  }
  .final__illustration h2 {
    color: rgb(37, 214, 37);
    text-align: center;
    transform: rotate(-6deg);
    font-family: "Proxima Nova Cn Rg";
  }
  .final__giphy {
    transform: rotate(1deg);
    width: 70%;
    margin: 2rem auto;
    justify-content: center;
    display: flex;
    height: 20rem;
    animation-name: gif;
    animation-duration: 2s;
  }
}
@keyframes design {
  0% {
    opacity: 0;
    transform: rotate(-350deg);
  }
  50% {
    opacity: 0.75;
    transform: rotate(40deg);
  }
  75% {
    opacity: 1;
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes gif {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes final_page {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@media screen and (max-width: 500px) {
  .modal,
  .overlay__black {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    animation: ease-in 0.2s Overtlay;
  }
  .overlay__black {
    background: rgba(0, 0, 0, 0.4);
  }
  .modal,
  .overlay__white {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    animation: ease-in 0.2s Overtlay;
  }
  .overlay__white {
    background: #fff;
  }
  .modal__input {
    width: 100%;
    margin: 1rem auto 0rem;
    height: 3.5rem;
    font-family: "Proxima Nova Cn Rg";
    font-size: 36px;
    font-weight: bold;
  }
  .modal__input div {
    display: flex;
    justify-content: center;
  }
  .modal__input div .input__split {
    border: transparent solid 0px;
    width: 80%;
    margin: auto;
    font-size: 36px;
    font-weight: 600;
    font-family: "Proxima Nova Cn Rg";
    text-align: center;
  }
  .modal__input div input:focus {
    outline: none;
    text-align: center;
  }
  .modal__input img {
    margin: -0.5rem auto 0rem;
    display: flex;
    width: 6rem;
    justify-content: center;
  }
  .modal__input .modal__design {
    border-bottom: rgb(43, 185, 178) solid 2px;
    width: 20%;
    height: 1rem;
    margin: -0.6rem auto;
  }
  .label {
    animation: ease-in 1s LoginLabel;
  }
}
@media screen and (min-width: 501px) and (max-width: 850px) {
  .modal,
  .overlay__black {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    animation: ease-in 0.2s Overtlay;
  }
  .overlay__white {
    background: #fff;
  }
  .modal,
  .overlay__white {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    animation: ease-in 0.2s Overtlay;
  }
  .overlay__black {
    background: rgba(0, 0, 0, 0.4);
  }
  .modal__input {
    width: 100%;
    margin: 1rem auto 0rem;
    height: 3.5rem;
    font-family: "Proxima Nova Cn Rg";
    font-size: 36px;
    font-weight: bold;
  }
  .modal__input div {
    display: flex;
    justify-content: center;
  }
  .modal__input div .input__split {
    border: transparent solid 0px;
    width: 80%;
    margin: auto;
    font-size: 36px;
    font-weight: 600;
    font-family: "Proxima Nova Cn Rg";
    text-align: center;
  }
  .modal__input div input:focus {
    outline: none;
    text-align: center;
  }
  .modal__input img {
    margin: -0.5rem auto 0rem;
    display: flex;
    width: 6rem;
    justify-content: center;
  }
  .modal__input .modal__design {
    border-bottom: rgb(43, 185, 178) solid 2px;
    width: 20%;
    height: 1rem;
    margin: -0.6rem auto;
  }
  .label {
    animation: ease-in 3s LoginLabel;
  }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
  .modal,
  .overlay__black {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    animation: ease-in 0.1s Overtlay;
  }
  .overlay__black {
    background: rgba(0, 0, 0, 0.4);
  }
  .modal,
  .overlay__white {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    animation: ease-in 0.1s Overtlay;
  }
  .overlay__white {
    background: #fff;
  }
  .modal__input {
    width: 100%;
    margin: 1rem auto 0rem;
    height: 3.5rem;
    font-family: "Proxima Nova Cn Rg";
    font-size: 36px;
    font-weight: bold;
  }
  .modal__input div {
    display: flex;
    justify-content: center;
  }
  .modal__input div .input__split {
    border: transparent solid 0px;
    width: 80%;
    margin: auto;
    font-size: 36px;
    font-weight: 600;
    font-family: "Proxima Nova Cn Rg";
    text-align: center;
  }
  .modal__input div input:focus {
    outline: none;
    text-align: center;
  }
  .modal__input img {
    margin: -0.5rem auto 0rem;
    display: flex;
    width: 6rem;
    justify-content: center;
  }
  .modal__input .modal__design {
    border-bottom: rgb(43, 185, 178) solid 2px;
    width: 20%;
    height: 1rem;
    margin: -0.6rem auto;
  }
  .label {
    animation: ease-in 1s LoginLabel;
  }
}
@media screen and (min-device-width: 1280px) {
  .modal,
  .overlay__black {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    animation: ease-in 0.2s Overtlay;
  }
  .overlay__black {
    background: rgba(0, 0, 0, 0.4);
  }
  .modal,
  .overlay__white {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    animation: ease-in 0.1s Overtlay;
  }
  .overlay__white {
    background: #fff;
  }
  .modal__input {
    width: 100%;
    margin: 1rem auto 0rem;
    height: 3.5rem;
    font-family: "Proxima Nova Cn Rg";
    font-size: 36px;
    font-weight: bold;
  }
  .modal__input div {
    display: flex;
    justify-content: center;
  }
  .modal__input div .input__split {
    border: transparent solid 0px;
    width: 80%;
    margin: auto;
    font-size: 36px;
    font-weight: 600;
    font-family: "Proxima Nova Cn Rg";
    text-align: center;
  }
  .modal__input div input:focus {
    outline: none;
    text-align: center;
  }
  .modal__input img {
    margin: -0.5rem auto 0rem;
    display: flex;
    width: 6rem;
    justify-content: center;
  }
  .modal__input .modal__design {
    border-bottom: rgb(43, 185, 178) solid 2px;
    width: 20%;
    height: 1rem;
    margin: -0.6rem auto;
  }
  .label {
    animation: ease-in 1s LoginLabel;
  }
}
@keyframes Overtlay {
  from {
    height: 200%;
  }
  to {
    height: 100%;
  }
}
@keyframes LoginLabel {
  from {
    height: 0%;
    opacity: 0;
  }
  to {
    height: 100%;
    opacity: 1;
  }
}
@media screen and (max-width: 500px) {
  .table__empty {
    height: 80vh;
    width: 100%;
    animation: EmptyAnimation 1s ease-in-out;
  }
  .table__empty .table__Image1 {
    border-top: 4.5rem solid transparent;
    width: 55%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .table__empty .table__Image2 {
    border-top: 0.5rem solid transparent;
    width: 25%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
}
@keyframes EmptyAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (min-width: 501px) and (max-width: 800px) {
  .table__empty {
    height: 60vh;
    width: 100%;
    animation: EmptyAnimation 1s ease-in-out;
  }
  .table__empty .table__Image1 {
    border-top: 2.5rem solid transparent;
    width: 40%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .table__empty .table__Image2 {
    border-top: 0.5rem solid transparent;
    width: 25%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
  .table__empty {
    height: 80vh;
    width: 100%;
    animation: EmptyAnimation 1s ease-in-out;
  }
  .table__empty .table__Image1 {
    border-top: 4.5rem solid transparent;
    width: 55%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .table__empty .table__Image2 {
    border-top: 0.5rem solid transparent;
    width: 25%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-device-width: 1280px) {
  .table__empty {
    height: 80vh;
    width: 100%;
    animation: EmptyAnimation 1s ease-in-out;
  }
  .table__empty .table__Image1 {
    border-top: 4.5rem solid transparent;
    width: 25%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .table__empty .table__Image2 {
    border-top: 0.5rem solid transparent;
    width: 25%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .spacing__words {
    letter-spacing: 8px;
  }
}
@media (min-device-width: 501px) and (max-device-width: 800px) {
  .spacing__words {
    letter-spacing: 8px;
  }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
  .spacing__words {
    letter-spacing: 8px;
  }
}
@media screen and (min-device-width: 1280px) {
  .spacing__words {
    letter-spacing: 8px;
  }
}
.CatherinaFont {
  font-family: "Caterina";
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.container {
  width: 90%;
  margin: auto;
}

@media screen and (max-width: 500px) {
  .loading__ {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .waiting__loader {
    width: 25vw;
    height: 10rem;
    margin: auto;
  }
  .header {
    text-transform: capitalize;
    font-family: "Caterina", sans-serif;
    width: 100%;
    text-align: center;
    height: 3rem;
    font-size: 30px;
  }
  .header h2 {
    font-size: 25px;
    font-family: "Caterina", sans-serif;
    font-weight: 800;
  }
  .home__header {
    width: 100%;
    height: 42vh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../images/IMG_9094.JPG");
    -webkit-mask-image: linear-gradient(to top, transparent 10%, white 50%);
            mask-image: linear-gradient(to top, transparent 10%, white 50%);
    animation: home__animation 1s;
    margin-bottom: 3rem;
  }
  .mask {
    background-color: #fff;
    width: 100%;
    height: 6rem;
    -webkit-mask-image: linear-gradient(to bottom, transparent 10%, rgb(255, 255, 255) 80%);
            mask-image: linear-gradient(to bottom, transparent 10%, rgb(255, 255, 255) 80%);
    animation: home__animation 1s;
  }
  .welcome__main {
    width: 90%;
    margin: 1vh auto 2vh;
    background-color: #fff;
    color: #121212;
    animation: home__animation 1s;
    height: 45vh;
  }
  .welcome__title {
    font-weight: 800;
    font-family: "Caterina", sans-serif;
    text-transform: capitalize;
    font-size: 44px;
    line-height: 50px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    letter-spacing: 1px;
    width: 100%;
  }
  .welcome__extras {
    font-weight: 300;
    line-height: 18px;
    text-transform: none;
    font-family: "Proxima Nova Cn Rg";
  }
  .welcome__article {
    width: 100%;
    margin-top: 1rem;
  }
  .welcome__tableBill {
    width: 100%;
    height: 4em;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    margin-top: 1rem;
    align-items: center;
  }
  .welcome__tableText1 {
    color: #121212;
    font-size: 20px;
    line-height: 20px;
    font-family: "Proxima Nova Cn Rg";
  }
  .welcome__tableText2 {
    color: #121212;
    font-size: 22px;
    line-height: 23px;
    font-family: "Proxima Nova Cn Rg";
  }
  .end {
    text-align: end;
  }
  .start {
    text-align: start;
  }
  .art {
    display: flex;
    height: 1rem;
    align-self: center;
    margin: 0rem auto;
    width: 80%;
    justify-content: space-between;
  }
  .art__circle {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #cfcfcf;
    margin: -1.2rem 0rem;
  }
  .art__line {
    background-color: #cfcfcf;
    width: 45%;
    height: 1px;
    margin: -1rem 0rem;
  }
  .footer {
    width: 100%;
    display: flex;
    height: 5vh;
    justify-content: center;
  }
  .footer h3 {
    margin-left: 1.5rem;
    font-family: "Caterina", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .footer img {
    margin-top: -0.5rem;
    margin-left: 0.4rem;
    width: 5.2rem;
    height: 1.8rem;
  }
  .foot {
    width: 100%;
    height: 5vh;
    display: flex;
    justify-content: center;
  }
  .foot h3 {
    padding-top: 2vh;
    margin-left: 1.5rem;
    font-family: "Caterina", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .foot img {
    padding-top: 2vh;
    margin-top: -0.5rem;
    margin-left: 0.4rem;
    width: 5.2rem;
    height: 1.8rem;
  }
  .icon__trash {
    color: red;
  }
  .icon__back {
    font-size: 34px;
    font-weight: 900;
  }
  .icon__modal {
    font-size: 34px;
    margin-top: 7px;
    margin-left: 25px;
  }
  .icon__bill {
    margin-left: 15px;
    margin-top: 8px;
  }
  .icon__home {
    font-size: 22px;
    margin-top: 8px;
  }
  .icon__sign {
    font-size: 21px;
  }
  .icon__scroll--right {
    width: 20px;
    margin-left: 15px;
  }
  .icon__scroll--left {
    width: 20px;
    margin-right: 15px;
  }
}
@media screen and (min-width: 501px) and (max-width: 800px) {
  .loading__page {
    width: 100%;
    min-height: 100vh;
    background: url("../images/menu-background.png");
  }
  .mask {
    background-color: #fff;
    width: 100%;
    height: 6rem;
    -webkit-mask-image: linear-gradient(to bottom, transparent 10%, rgb(255, 255, 255) 80%);
            mask-image: linear-gradient(to bottom, transparent 10%, rgb(255, 255, 255) 80%);
    animation: home__animation 1s;
  }
  .loading__spinner {
    margin: auto;
    padding-top: 20vh;
  }
  .loading__ {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .header {
    text-transform: capitalize;
    font-family: "Caterina", sans-serif;
    width: 100%;
    text-align: center;
    height: 3rem;
    font-size: 30px;
  }
  .home__header {
    width: 100%;
    height: 43vh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../images/IMG_9094.JPG");
    -webkit-mask-image: linear-gradient(to top, transparent 10%, white 50%);
            mask-image: linear-gradient(to top, transparent 10%, white 50%);
    animation: home__animation 1s;
  }
  .container {
    width: 90%;
    margin: auto;
  }
  .welcome__main {
    width: 85%;
    height: 50vh;
    margin: 1vh auto 2vh;
    background-color: #fff;
    color: #121212;
    animation: home__animation 1s;
  }
  .welcome__title {
    font-weight: 600;
    font-family: "Caterina", sans-serif;
    text-transform: capitalize;
    font-size: 44px;
    line-height: 26px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    letter-spacing: 1px;
    width: 100%;
  }
  .welcome__extras {
    font-weight: 300;
    line-height: 22px;
    text-transform: none;
    font-size: 20px;
    font-family: "Proxima Nova Cn Rg";
  }
  .welcome__article {
    width: 100%;
    margin-top: 1rem;
  }
  .welcome__tableBill {
    width: 100%;
    height: 4em;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 0rem;
    align-items: center;
  }
  .welcome__tableText1 {
    color: #121212;
    font-size: 20px;
    line-height: 20px;
  }
  .welcome__tableText2 {
    color: #121212;
    font-size: 22px;
    line-height: 23px;
  }
  .end {
    text-align: end;
  }
  .start {
    text-align: start;
  }
  .art {
    display: flex;
    height: 1rem;
    align-self: center;
    margin: 0rem auto;
    width: 80%;
    justify-content: space-between;
  }
  .art__circle {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #cfcfcf;
    margin: -1.2rem 0rem;
  }
  .art__line {
    background-color: #cfcfcf;
    width: 45%;
    height: 1px;
    margin: -1rem 0rem;
  }
  .footer {
    width: 100%;
    display: flex;
    height: 5vh;
    justify-content: center;
  }
  .footer h3 {
    margin-left: 1.5rem;
    font-style: "Caterina", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .footer img {
    margin-top: -0.5rem;
    margin-left: 0.4rem;
    width: 5.2rem;
    height: 1.8rem;
  }
  .foot {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 5vh;
  }
  .foot h3 {
    margin-left: 1.5rem;
    font-style: "Caterina", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .foot img {
    margin-top: -0.5rem;
    margin-left: 0.4rem;
    width: 5.2rem;
    height: 1.8rem;
  }
  .icon__trash {
    color: red;
  }
  .icon__back {
    font-size: 24px;
    margin-top: 4px;
  }
  .icon__modal {
    font-size: 34px;
    margin-top: 7px;
    margin-left: 25px;
  }
  .icon__bill {
    margin-left: 15px;
    margin-top: 8px;
  }
  .icon__home {
    font-size: 22px;
    margin-top: 8px;
  }
  .icon__sign {
    font-size: 21px;
  }
  .icon__scroll--right {
    width: 20px;
    margin-top: 5px;
    margin-left: 15px;
  }
  .icon__scroll--left {
    width: 20px;
    margin-top: 5px;
    margin-right: 15px;
  }
}
@media (min-device-width: 801px) and (max-device-width: 1280px) {
  .loading__page {
    width: 100%;
    min-height: 100vh;
    background: url("../images/menu-background.png");
  }
  .mask {
    background-color: #fff;
    width: 100%;
    height: 6rem;
    -webkit-mask-image: linear-gradient(to bottom, transparent 100%, rgb(255, 255, 255) 100%);
            mask-image: linear-gradient(to bottom, transparent 100%, rgb(255, 255, 255) 100%);
    animation: home__animation 1s;
  }
  .loading__spinner {
    margin: auto;
    padding-top: 20vh;
  }
  .loading__ {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .header {
    text-transform: capitalize;
    font-family: "Caterina", sans-serif;
    width: 100%;
    text-align: center;
    height: 3rem;
    font-size: 30px;
  }
  .home__header {
    width: 100%;
    height: 40vh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../images/IMG_9094.JPG");
    -webkit-mask-image: linear-gradient(to top, transparent 10%, white 50%);
            mask-image: linear-gradient(to top, transparent 10%, white 50%);
    animation: home__animation 1s;
  }
  .container {
    width: 90%;
    margin: auto;
  }
  .welcome__main {
    width: 90%;
    margin: 1rem auto 2rem;
    background-color: #fff;
    color: #121212;
    height: 50vh;
    animation: home__animation 1s;
  }
  .welcome__title {
    font-weight: 600;
    font-family: "Caterina", sans-serif;
    text-transform: capitalize;
    font-size: 40px;
    line-height: 42px;
    margin-bottom: 1rem;
    letter-spacing: 1px;
    width: 100%;
  }
  .welcome__extras {
    font-weight: 300;
    line-height: 20px;
    text-transform: none;
    font-size: 20px;
    font-family: "Proxima Nova Cn Rg";
  }
  .welcome__article {
    width: 100%;
    margin-top: 1rem;
  }
  .welcome__tableBill {
    width: 100%;
    height: 5em;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0rem;
    margin-top: 0.5em;
    align-items: center;
  }
  .welcome__tableText1 {
    color: #121212;
    font-size: 20px;
    line-height: 24px;
    font-family: "Proxima Nova Cn Rg";
  }
  .welcome__tableText2 {
    color: #121212;
    font-size: 22px;
    font-family: "Proxima Nova Cn Rg";
    line-height: 23px;
  }
  .end {
    text-align: end;
  }
  .start {
    text-align: start;
  }
  .art {
    display: flex;
    height: 1rem;
    align-self: center;
    margin: 0rem auto;
    width: 80%;
    justify-content: space-between;
  }
  .art__circle {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #cfcfcf;
    margin: -1.2rem 0rem;
  }
  .art__line {
    background-color: #cfcfcf;
    width: 45%;
    height: 1px;
    margin: -1rem 0rem;
  }
  .footer {
    width: 100%;
    display: flex;
    height: 5vh;
    justify-content: center;
  }
  .footer h3 {
    margin-left: 1.5rem;
    font-style: "Caterina", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .footer img {
    margin-top: -0.5rem;
    margin-left: 0.4rem;
    width: 5.2rem;
    height: 1.8rem;
  }
  .foot {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 5vh;
  }
  .foot h3 {
    margin-left: 1.5rem;
    font-style: "Caterina", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .foot img {
    margin-top: -0.5rem;
    margin-left: 0.4rem;
    width: 5.2rem;
    height: 1.8rem;
  }
  .icon__trash {
    color: red;
  }
  .icon__back {
    font-size: 24px;
    margin-top: 4px;
  }
  .icon__modal {
    font-size: 34px;
    margin-top: 7px;
    margin-left: 25px;
  }
  .icon__bill {
    margin-left: 15px;
    margin-top: 8px;
  }
  .icon__home {
    font-size: 22px;
    margin-top: 8px;
  }
  .icon__sign {
    font-size: 21px;
  }
  .icon__scroll {
    width: 20px;
    margin-left: 15px;
  }
  .icon__scroll--left {
    display: none;
  }
  .icon__scroll--right {
    display: none;
  }
}
@media screen and (min-device-width: 1280px) {
  .error {
    width: 100%;
    min-height: 100vh;
    background: url("../images/menu-background.png");
  }
  .error__image {
    width: 30%;
    height: 4rem;
    padding-top: 10vh;
    margin: auto;
  }
  .error__image h2 {
    text-align: center;
    text-transform: capitalize;
    font-weight: 800;
    letter-spacing: 2px;
    font-size: 50px;
    line-height: 40px;
    margin: 2rem auto;
    font-family: "Caterina", sans-serif;
  }
  .error__image img {
    width: 100%;
    border-radius: 5%;
  }
  .error__image p {
    margin: 2rem auto;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
  }
  .loading__page {
    width: 100%;
    min-height: 100vh;
    background: url("../images/menu-background.png");
  }
  .loading__spinner {
    margin: auto;
    padding-top: 20vh;
  }
  .loading__ {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .header {
    text-transform: capitalize;
    font-family: "Caterina", sans-serif;
    width: 100%;
    text-align: center;
    height: 3rem;
    font-size: 30px;
  }
  .header h2 {
    font-size: 25px;
    font-family: "Caterina", sans-serif;
    font-weight: bold;
  }
  .home__header {
    width: 100%;
    height: 75vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../images/IMG_9094.JPG");
    -webkit-mask-image: linear-gradient(to top, transparent 10%, white 50%);
            mask-image: linear-gradient(to top, transparent 10%, white 50%);
    animation: home__animation 1s;
  }
  .container {
    width: 90%;
    margin: auto;
  }
  .welcome__main {
    width: 90%;
    margin: 1vh auto 2vh;
    background-color: #fff;
    color: #121212;
    animation: home__animation 1s;
    height: 45vh;
  }
  .welcome__title {
    font-weight: 600;
    font-family: "Caterina", sans-serif;
    text-transform: capitalize;
    font-size: 44px;
    line-height: 50px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    letter-spacing: 1px;
    width: 100%;
    text-align: center;
  }
  .welcome__extras {
    font-weight: 300;
    line-height: 18px;
    text-transform: none;
    text-align: center;
    font-family: "Proxima Nova Cn Rg";
  }
  .welcome__article {
    width: 100%;
    margin-top: 1rem;
  }
  .welcome__tableBill {
    width: 100%;
    height: 4em;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    margin-top: 1rem;
    align-items: center;
    font-family: "Proxima Nova Cn Rg";
  }
  .welcome__tableText1 {
    color: #121212;
    font-size: 20px;
    line-height: 20px;
    font-family: "Proxima Nova Cn Rg";
  }
  .welcome__tableText2 {
    color: #121212;
    font-size: 22px;
    line-height: 23px;
    font-family: "Proxima Nova Cn Rg";
  }
  .end {
    text-align: end;
  }
  .start {
    text-align: start;
  }
  .art {
    display: flex;
    height: 1rem;
    align-self: center;
    margin: 0rem auto;
    width: 80%;
    justify-content: space-between;
  }
  .art__circle {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #cfcfcf;
    margin: -1.2rem 0rem;
  }
  .art__line {
    background-color: #cfcfcf;
    width: 45%;
    height: 1px;
    margin: -1rem 0rem;
  }
  .footer {
    width: 100%;
    display: flex;
    height: 5vh;
    justify-content: center;
  }
  .footer h3 {
    margin-left: 1.5rem;
    font-style: "Caterina", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .footer img {
    margin-top: -0.5rem;
    margin-left: 0.4rem;
    width: 5.2rem;
    height: 1.8rem;
  }
  .foot {
    width: 100%;
    height: 5vh;
    display: flex;
    justify-content: center;
  }
  .foot h3 {
    padding-top: 2vh;
    margin-left: 1.5rem;
    font-style: "Caterina", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .foot img {
    padding-top: 2vh;
    margin-top: -0.5rem;
    margin-left: 0.4rem;
    width: 5.2rem;
    height: 1.8rem;
  }
  .icon__trash {
    color: red;
  }
  .icon__back {
    font-size: 24px;
    margin-top: 4px;
  }
  .icon__modal {
    font-size: 34px;
    margin-top: 7px;
    margin-left: 25px;
  }
  .icon__bill {
    margin-left: 15px;
    margin-top: 8px;
  }
  .icon__home {
    font-size: 22px;
    margin-top: 8px;
  }
  .icon__sign {
    font-size: 21px;
  }
  .icon__scroll--right {
    display: none;
    width: 20px;
    margin-left: 15px;
  }
  .icon__scroll--left {
    display: none;
    width: 20px;
    margin-right: 15px;
  }
}
@keyframes home__main__animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes home__animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}/*# sourceMappingURL=index.css.map */